import * as Realm from "realm-web";
import { MONGO_APP_ID } from "@configs/env";
import { Franchise, FranchiseGateway } from "@core/gateways/franchise/FranchiseGateway";

export class RealMWebFranchiseGateway implements FranchiseGateway {
  async getFranchises(): Promise<Franchise[]> {
    try {
      const app = new Realm.App({ id: MONGO_APP_ID });
      const credentials = Realm.Credentials.emailPassword("admin@creoodot.com", "Azerty123*");

      const user = await app.logIn(credentials);
      const prospects: Franchise[] = await user.functions.getAllUsers();

      return prospects;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async updateFranchise(franchise: Franchise, value: "unsended" | "sended"): Promise<Franchise> {
    try {
      const app = new Realm.App({ id: MONGO_APP_ID });
      const credentials = Realm.Credentials.emailPassword("admin@creoodot.com", "Azerty123*");

      const user = await app.logIn(credentials);
      const prospect: Franchise = await user.functions.updateUser(
        new Realm.BSON.ObjectID(franchise._id).toString(),
        value === "sended" ? true : false
      );

      return prospect;
    } catch (error) {
      console.log(error);
      return franchise;
    }
  }
}
