import React from "react";
import { Separator } from "@app/components/ui/separator";
import { SidebarNav } from "@app/components/sidebar-nav/sidebar-nav-user";
import { userRoutes } from "@app/routes/user/urls";
import { Outlet } from "react-router-dom";
import { ResizablePanel } from "@app/components/ui/resizable";
import { defaultLayout } from "../AuthLayout";

const sidebarNavItems = [
  {
    title: "Profile",
    to: userRoutes.PROFILE,
  },
  {
    title: "Account",
    to: userRoutes.ACCOUNT,
  },
  {
    title: "Appearance",
    to: userRoutes.APPEARANCE,
  },
  {
    title: "Notifications",
    to: userRoutes.NOTIFICATIONS,
  },
];

const SettingsLayout: React.FC = () => {
  return (
    <ResizablePanel defaultSize={defaultLayout[2]} minSize={30}>
      <div className="space-y-6 p-10 pb-0 flex flex-col h-full">
        <div className="space-y-0.5">
          <h2 className="text-2xl font-bold tracking-tight">Settings</h2>
          <p className="text-muted-foreground">Manage your account settings and set e-mail preferences.</p>
        </div>
        <Separator className="my-6" />
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0 flex-1 overflow-x-scroll sc">
          <aside className="lg:w-1/5 md:sticky top-0 bg-white dark:bg-neutral-950 pb-4">
            <SidebarNav items={sidebarNavItems} />
          </aside>
          <div className="flex-1 lg:max-w-2xl">
            <Outlet />
            <div className="h-10" />
          </div>
        </div>
      </div>
    </ResizablePanel>
  );
};

export default SettingsLayout;
