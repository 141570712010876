import { Profile } from "@core/gateways/profile/profileGateway";

export const profileUserMock: Profile = {
  id: 1,
  username: "eziouane",
  email: "eric@creodot.com",
  displayName: "Eric Ziouane",
  firstName: "Eric",
  bio: "I am a user",
  lastName: "Ziouane",
  role: "USER",
  notifications: {
    communication: true,
    security: false,
    push: true,
  },
};
