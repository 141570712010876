import { createReducer } from "@reduxjs/toolkit";
import { AppState } from "@store/appState";
import { signinAction } from "@core/usecases/authentication/signin/signinAction";
import { signoutAction } from "@core/usecases/authentication/signout/signoutAction";
import { updateNotificationsAction } from "@core/usecases/profile/update-notifications/updateNotificationsAction";
import { getProfileAction } from "@core/usecases/profile/get-profile/getProfileAction";
import { updateProfileAction } from "@core/usecases/profile/update-profile/updateProfileAction";
import { updateAccountAction } from "@core/usecases/profile/update-account/updateAccountAction";
import { resetPasswordAction } from "@core/usecases/authentication/reset-password/resetPasswordAction";

const initialState: AppState["profile"] = {
  id: 0,
  email: "",
  role: "GUEST",
  username: "",
  displayName: "",
  firstName: "",
  bio: "",
  lastName: "",
  notifications: {
    security: false,
    communication: false,
    push: false,
  },
};

export const profileReducer = createReducer<AppState["profile"]>(initialState, builder => {
  /**
   * Sign in
   */
  builder.addCase(signinAction, (state, { payload }) => {
    if (payload?.profile && state) {
      state.id = payload.profile.id;
      state.email = payload.email;
      state.notifications = payload.profile.notifications;
      state.role = payload.profile.role;
      state.firstName = payload.profile.firstName;
      state.lastName = payload.profile.lastName;
      state.username = payload.profile.username;
      state.displayName = payload.profile.displayName;
      state.bio = payload.profile.bio;
    }
  });

  /**
   * resetPasswordAction
   */
  builder.addCase(resetPasswordAction, (state, { payload }) => {
    if (payload?.profile && state) {
      state.id = payload.profile.id;
      state.email = payload.email;
      state.notifications = payload.profile.notifications;
      state.firstName = payload.profile.firstName;
      state.lastName = payload.profile.lastName;
      state.username = payload.profile.username;
      state.bio = payload.profile.bio;
      state.displayName = payload.profile.displayName;
    }
  });

  /**
   * Update notifications
   */
  builder.addCase(updateNotificationsAction, (state, { payload }) => {
    if (payload && state?.notifications) {
      state.notifications = payload;
    }
  });

  /**
   * Get profile
   */
  builder.addCase(getProfileAction, (state, { payload }) => {
    if (!payload || !state) return;

    state.id = payload.id;
    state.email = payload.email;
    state.notifications = payload.notifications;
    state.firstName = payload.firstName;
    state.lastName = payload.lastName;
    state.username = payload.username;
    state.bio = payload.bio;
    state.displayName = payload.displayName;
  });

  /**
   * Update profile
   */
  builder.addCase(updateProfileAction, (state, { payload }) => {
    if (!payload || !state) return;

    state.bio = payload.bio ?? "";
    state.username = payload.username;
  });

  /**
   * Update account
   */
  builder.addCase(updateAccountAction, (state, { payload }) => {
    if (!payload || !state) return;

    state.displayName = payload.displayName;
  });

  /**
   * Sign out
   */
  builder.addCase(signoutAction, state => {
    if (!state) return;

    state.id = 0;
    state.email = "";
    state.role = "GUEST";
    state.username = "";
    state.firstName = "";
    state.lastName = "";
    state.displayName = "";
    state.notifications = {
      security: false,
      communication: false,
      push: false,
    };
  });
});
