import { Account, Profile, ProfileGateway, UpdateProfileType } from "@core/gateways/profile/profileGateway";

export class LocalProfileGateway implements ProfileGateway {
  async getProfile(): Promise<Profile | null> {
    try {
      return new Promise(resolve => {
        resolve(null);
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async updateNotifications(
    notifications: Profile["notifications"],
    profile: Profile
  ): Promise<Profile["notifications"] | null> {
    console.log(profile);
    try {
      return new Promise(resolve => {
        resolve(notifications);
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async updateProfile(newProfile: UpdateProfileType, profile: Profile): Promise<UpdateProfileType> {
    console.log(profile);
    return new Promise(resolve => {
      resolve(newProfile);
    });
  }

  async updateAccount(account: Account, profile: Profile): Promise<Account | null> {
    console.log(profile);
    return new Promise(resolve => {
      resolve(account);
    });
  }
}
