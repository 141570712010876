import React, { useEffect, useState } from "react";
import { useToast } from "@app/components/ui/use-toast";
import { useAppDispatch, useAppSelector } from "@store/reduxStore";
import { getVoices } from "@core/usecases/text-to-speech/get-voices/getVoices";
import { Voice } from "@core/gateways/text-to-speech/textToSpeechGateway";
import { Button } from "@app/components/ui/button";
import { Textarea } from "@app/components/ui/textarea";
import { DownloadIcon, PauseCircle, PlayCircle } from "lucide-react";
import { convert } from "@core/usecases/text-to-speech/convert/convert";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@app/components/ui/dropdown-menu";

const TextToSpeech: React.FC = (): JSX.Element => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const { voices: voiceList, result } = useAppSelector(state => state.feature.textToSpeech);

  const [loading, setLoading] = useState(false);
  const [voice, setVoice] = useState<Voice>();
  const [text, setText] = useState("");

  const [audio, setAudio] = useState<HTMLAudioElement>();

  useEffect(() => {
    if (!result) {
      return;
    }

    const audio = new Audio(URL.createObjectURL(result));
    setAudio(audio);
  }, [result]);

  useEffect(() => {
    setVoice(voiceList[0]);
  }, [voiceList]);

  useEffect(() => {
    dispatch(getVoices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConvert = async (): Promise<void> => {
    if (!voice) {
      toast({
        title: "Error",
        description: "No voice selected",
      });
      return;
    }

    setLoading(true);
    try {
      await dispatch(convert(text, voice));
    } catch (error) {
      toast({
        title: "Error",
        description: JSON.stringify(error),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSelectVoice = (e: string): void => {
    const selectedVoice = voiceList.find(voice => voice.label.toUpperCase() === e.toUpperCase());
    setVoice(selectedVoice);
  };

  const playSound = async (): Promise<void> => {
    if (!result || !audio) {
      toast({
        title: "Error",
        description: "No generated sound file yet",
      });
      return;
    }
    await audio.play();
  };

  const pauseSound = () => {
    if (!result || !audio) {
      toast({
        title: "Error",
        description: "No generated sound file yet",
      });
      return;
    }

    audio.pause();
  };

  const downloadSound = () => {
    if (!result) {
      toast({
        title: "Error",
        description: "No generated sound file yet",
      });
      return;
    }

    const url = URL.createObjectURL(result);
    const a = document.createElement("a");
    a.href = url;
    a.download = "generated-sound.mp3";
    a.click();

    toast({
      title: "Success",
      description: "Downloaded",
    });
  };

  return (
    <div className="w-full h-full relative text-p-2 text-xl transition-colors">
      <div className="w-full h-full flex flex-col items-center justify-center mb-10 z-10">
        <div className="w-[80%] z-50">
          <div className="flex justify-center mb-10 text-4xl font-medium text-slate-800 dark:text-slate-200 select-none">
            <span className="text-yellow-500 dark:text-yellow-500">Open</span> Voice
          </div>
          <div className="flex flex-col">
            <div className="mb-2 text-lg">Select voice</div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">{voice?.label}</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuLabel>Panel Position</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuRadioGroup value={voice?.label} onValueChange={e => handleSelectVoice(e)}>
                  {voiceList.map(el => (
                    <DropdownMenuRadioItem key={el.value} value={el.value}>
                      {el.label}
                    </DropdownMenuRadioItem>
                  ))}
                </DropdownMenuRadioGroup>
              </DropdownMenuContent>
            </DropdownMenu>
            <div className="mb-8" />
            <div className="mb-2 text-lg">Write text</div>
            <Textarea
              placeholder="I'm open voice of Creodot"
              value={text}
              onChange={e => setText(e.target.value)}
              required
            />
            <div className="mb-10" />
            <Button onClick={() => handleConvert()} disabled={loading}>
              Get result
            </Button>
          </div>
        </div>
      </div>
      {result ? (
        <div className="sticky bottom-5 left-0 right-0 z-50 border-t border-slate-300 p-4 mx-5">
          <div className="flex gap-10 justify-center items-center">
            <Button onClick={() => playSound()}>
              <PlayCircle />
              Play
            </Button>
            <Button onClick={() => pauseSound()}>
              <PauseCircle />
              Pause
            </Button>
            <Button onClick={() => downloadSound()}>
              <DownloadIcon />
              Download
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TextToSpeech;
