import TextToImage from "@app/pages/connected/feature/text-to-image/TextToImage";
import { featureRoutes } from "./urls";
import TextToSpeech from "@app/pages/connected/feature/text-to-speech/TextToSpeech";
import FranchiseList from "@app/pages/connected/feature/franchise/franchise-list/FranchiseList";
import EnigmAI from "@app/pages/connected/feature/enigmai/chat/Chat";
import Editorz from "@app/pages/connected/feature/editorz/Editorz";

export const featureRouter = [
  { path: featureRoutes.DASHBOARD },
  {
    path: featureRoutes.TEXT_TO_IMAGE,
    element: <TextToImage />,
  },
  {
    path: featureRoutes.TEXT_TO_SPEECH,
    element: <TextToSpeech />,
  },
  {
    path: featureRoutes.FRANCHISE_SCRAPPER,
    element: <FranchiseList />,
  },
  {
    path: featureRoutes.ENIGMAI,
    element: <EnigmAI />,
  },
  {
    path: featureRoutes.EDITORZ,
    element: <Editorz />,
  },
];
