import { indexRoutes } from "@app/routes/urls";
import { APP_URL } from "@configs/env";
import { AuthenticationGateway, SigninResponse } from "@core/gateways/authentication/authenticationGateway";
import { ToastGateway } from "@core/gateways/toast/toastGateway";
import { Email } from "@core/types/authentication/email";
import { Password } from "@core/types/authentication/password";
import { Supabase } from "@services/supabase";
import { Session } from "@supabase/supabase-js";

export class SupabaseAuthenticationGateway implements AuthenticationGateway {
  protected cloud: Supabase;
  protected toast: ToastGateway;

  constructor(cloud: Supabase, toast: ToastGateway) {
    this.cloud = cloud;
    this.toast = toast;
  }

  async signin(email: Email, password: Password): Promise<SigninResponse | null> {
    try {
      const { data, error } = await this.cloud.auth.signInWithPassword({
        email: email.toString(),
        password: password.toString(),
      });

      if (error) {
        this.toast.show("⛔️ Error", `${error.message} !`);
        throw new Error(error.message);
      }

      if (!data.session) {
        this.toast.show("⛔️ Error", `Can't create session !`);
        throw new Error("No session found");
      }

      const { data: profile } = await this.cloud.from("profile").select("*").eq("user_id", data.user.id).single();

      if (profile?.user_id) {
        this.toast.show("✅ Success", `Welcome ${profile.firstName ?? profile.email} !`);

        return {
          email: profile.email,
          profile: {
            id: profile.id,
            email: profile.email,
            role: profile.role,
            firstName: profile.firstName ?? "",
            lastName: profile.lastName ?? "",
            username: profile.username ?? "",
            displayName: profile.displayName ?? "",
            bio: profile.bio ?? "",
            notifications: {
              push: profile.push,
              communication: profile.communication,
              security: profile.security,
            },
          },
        };
      }

      this.toast.show("⛔️ Error", `Email not provided !`);
      return null;
    } catch (error) {
      this.toast.show("⛔️ Error", `Something went wrong !`);
      return null;
    }
  }

  async askResetPassword(email: Email): Promise<void> {
    try {
      await this.cloud.auth.resetPasswordForEmail(email.toString(), {
        redirectTo: `${APP_URL}${indexRoutes.RESET_PASSWORD}`,
      });

      this.toast.show("✅ Success", `Check your email !`);
    } catch (error) {
      this.toast.show("⛔️ Error", `Something went wrong !`);
    }
  }

  async resetPassword(newPassword: Password, { access_token, refresh_token }: Session): Promise<SigninResponse | null> {
    try {
      await this.cloud.auth.setSession({
        access_token,
        refresh_token,
      });

      const { data: info, error } = await this.cloud.auth.updateUser({ password: newPassword.toString() });

      if (error) {
        this.toast.show("⛔️ Error", `${error.message} !`);
        return null;
      }

      const { data: profile } = await this.cloud.from("profile").select("*").eq("user_id", info.user.id).single();

      if (profile?.email) {
        this.toast.show("✅ Success", `Welcome ${profile.firstName ?? profile.email} !`);

        return {
          email: profile.email,
          profile: {
            id: profile.id,
            email: profile.email,
            role: profile.role,
            firstName: profile.firstName ?? "",
            lastName: profile.lastName ?? "",
            username: profile.username ?? "",
            displayName: profile.displayName ?? "",
            bio: profile.bio ?? "",
            notifications: {
              push: profile.push,
              communication: profile.communication,
              security: profile.security,
            },
          },
        };
      }

      this.toast.show("⛔️ Error", `Email not provided !`);

      return null;
    } catch (error) {
      this.toast.show("⛔️ Error", `Something went wrong !`);
      return null;
    }
  }

  async signout(): Promise<boolean> {
    try {
      this.toast.show("✅ Success", `See you soon !`);
      await this.cloud.auth.signOut();
      return true;
    } catch (error) {
      this.toast.show("✅ Success", `See you soon !`);
      return true;
    }
  }
}
