import {
  Action,
  combineReducers,
  configureStore,
  Store,
  ThunkAction,
  ThunkDispatch,
  UnknownAction,
} from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import storage from "redux-persist/lib/storage";
import { appReducer as app } from "./reducers/appReducer";
import { authenticationReducer as authentication } from "./reducers/authenticationReducer";
import { featureReducer as feature } from "./reducers/featureReducer";
import { profileReducer as profile } from "./reducers/profileReducer";
import { AppGateway } from "@core/gateways/app/appGateway";
import { AuthenticationGateway } from "@core/gateways/authentication/authenticationGateway";
import { MODE, PERSISTED_KEY } from "@configs/env";
import { AppState } from "./appState";
import { ToastGateway } from "@core/gateways/toast/toastGateway";
import { ProfileGateway } from "@core/gateways/profile/profileGateway";
import { TextToImageGateway } from "@core/gateways/text-to-image/textToImageGateway";
import { TextToSpeechGateway } from "@core/gateways/text-to-speech/textToSpeechGateway";
import { FranchiseGateway } from "@core/gateways/franchise/FranchiseGateway";
import { EditorzGateway } from "@core/gateways/editorz/EditorzGateway";

/**
 * Dependencies
 */
export interface Dependencies {
  appGateway: AppGateway;
  authenticationGateway: AuthenticationGateway;
  profileGateway: ProfileGateway;
  toastGateway: ToastGateway;
  textToImageGateway: TextToImageGateway;
  textToSpeechGateway: TextToSpeechGateway;
  franchiseGateway: FranchiseGateway;
  editorzGateway: EditorzGateway;
}

const persistConfig = {
  key: `${PERSISTED_KEY}-root`,
  version: 1,
  storage,
  blacklist: [],
};

const rootReducer = combineReducers({
  app,
  authentication,
  profile,
  feature,
});

// Persist data in app (like localstorage)
const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * Redux initialization
 *
 * @see {@link https://redux.js.org | Redux}
 * @param dependencies
 * @returns
 */
export const initReduxStore = (dependencies: Partial<Dependencies>) => {
  return configureStore({
    reducer: persistedReducer,
    devTools: MODE !== "production",
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: { extraArgument: dependencies },
        serializableCheck: false,
      }),
  });
};

export type ReduxStore = Store<AppState> & {
  dispatch: ThunkDispatch<AppState, Dependencies, Action>;
};

// Type for Redux Env. to get dispatch/getState/Dependencies injections
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, Dependencies, UnknownAction>;

// Type for useAppDispatch hook
// use in core/
export type AppDispatch = ThunkDispatch<AppState, Dependencies, Action>;

/**
 * useAppDispatch Hook
 *
 * Execute fonction inside Redux to affect data in global state
 *
 * @example
 * ```ts
 * const dispatch = useAppDispatch();
 * dispatch(login(email, password));
 * ```
 */
export const useAppDispatch: () => AppDispatch = useDispatch;

/**
 * useAppSelector Hook
 *
 * Get data from global state
 *
 * @example
 * Get token from global state:
 * ```ts
 * const token = useAppSelector(state => state.authentication.token);
 * ```
 *
 * @returns AppState
 */
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
