import { TextToSpeechGateway, Voice } from "@core/gateways/text-to-speech/textToSpeechGateway";
import { ToastGateway } from "@core/gateways/toast/toastGateway";
import { Supabase } from "@services/supabase";

export class SupabaseTextToSpeechGateway implements TextToSpeechGateway {
  protected cloud: Supabase;
  protected toast: ToastGateway;

  constructor(cloud: Supabase, toast: ToastGateway) {
    this.cloud = cloud;
    this.toast = toast;
  }

  async convert(text: string, type: Voice): Promise<Blob | null> {
    const { data } = await this.cloud.functions.invoke<Blob | null>("text-to-speech", {
      body: JSON.stringify({ prompt: text, voice: type.value }),
    });

    return data;
  }

  async getVoices(): Promise<Voice[]> {
    return new Promise(res =>
      res([
        { value: "alloy", label: "Alloy" },
        { value: "echo", label: "Echo" },
        { value: "fable", label: "Fable" },
        { value: "onyx", label: "Onyx" },
        { value: "nova", label: "Nova" },
        { value: "shimmer", label: "Shimmer" },
      ])
    );
  }
}
