import { Account, Profile, ProfileGateway, UpdateProfileType } from "@core/gateways/profile/profileGateway";
import { ToastGateway } from "@core/gateways/toast/toastGateway";
import { Supabase } from "@services/supabase";

export class SupabaseProfileGateway implements ProfileGateway {
  protected cloud: Supabase;
  protected toast: ToastGateway;

  constructor(cloud: Supabase, toast: ToastGateway) {
    this.cloud = cloud;
    this.toast = toast;
  }

  async getProfile(): Promise<Profile | null> {
    try {
      const { data } = await this.cloud.auth.getSession();

      if (!data?.session) {
        this.toast.show("⛔️ Error", "Session not found");
        return null;
      }

      const { data: profile } = await this.cloud
        .from("profile")
        .select("*")
        .eq("email", data.session.user.email!)
        .single();

      if (!profile) {
        this.toast.show("⛔️ Error", "Profile not found");
        return null;
      }

      this.toast.show("👋 Welcome", "");

      return {
        id: profile.id,
        email: profile.email,
        role: profile.role,
        username: profile.username ?? "",
        displayName: profile.displayName ?? "",
        firstName: profile.firstName ?? "",
        lastName: profile.lastName ?? "",
        bio: profile.bio ?? "",
        notifications: {
          push: profile.push,
          communication: profile.communication,
          security: profile.security,
        },
      };
    } catch (error) {
      this.toast.show("⛔️ Error", "Error getting profile");
      console.log(error);
      return null;
    }
  }

  async updateNotifications(notifications: Profile["notifications"]): Promise<Profile["notifications"] | null> {
    try {
      const { data } = await this.cloud.auth.getUser();

      if (!data?.user?.id) {
        this.toast.show("⛔️ Error", "User not found");
        return null;
      }

      await this.cloud
        .from("profile")
        .update({
          push: notifications.push,
          communication: notifications.communication,
          security: notifications.security,
        })
        .eq("user_id", data.user.id);

      this.toast.show("✅ Success", "Notifications updated");
      return notifications;
    } catch (error) {
      this.toast.show("⛔️ Error", "Error updating notifications");
      console.log(error);
      return null;
    }
  }

  async updateProfile(newProfile: UpdateProfileType, profile: Profile): Promise<UpdateProfileType | null> {
    try {
      const { error } = await this.cloud
        .from("profile")
        .update({
          username: newProfile.username,
          bio: newProfile.bio,
        })
        .eq("id", profile.id);

      if (error) {
        this.toast.show("⛔️ Error", `Error: ${error.message}`);
        return null;
      }

      this.toast.show("✅ Success", "Profile updated");
      return newProfile;
    } catch (error) {
      return null;
    }
  }

  async updateAccount(account: Account, profile: Profile): Promise<Account | null> {
    try {
      const { error } = await this.cloud
        .from("profile")
        .update({
          displayName: account.displayName,
        })
        .eq("id", profile.id);

      if (error) {
        this.toast.show("⛔️ Error", `Error: ${error.message}`);
        return null;
      }

      this.toast.show("✅ Success", "Account updated");
      return account;
    } catch (error) {
      return null;
    }
  }
}
