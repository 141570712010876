import React from "react";
// import { useAppDispatch, useAppSelector } from "@store/reduxStore";

const Editorz: React.FC = (): JSX.Element => {
  // const dispatch = useAppDispatch();
  // const editorzState = useAppSelector(state => state.feature.editorz);

  return (
    <div className="h-full text-p-2 text-xl transition-colors overflow-auto">
      <div className="flex flex-col py-10 mx-auto container">
        <div className="flex justify-between items-center mb-10 text-4xl font-medium select-none">
          <div>
            <span className="text-cyan-700 dark:text-cyan-700">Editor</span>Z
          </div>
        </div>

        <div className="mb-10 text-sm font-medium select-none"></div>

        <div className="flex-1 flex flex-wrap justify-between gap-4 ml-auto mr-auto"></div>
      </div>
    </div>
  );
};

export default Editorz;
