import React, { useEffect, useState } from "react";
import { Franchise } from "@core/gateways/franchise/FranchiseGateway";
import { useAppDispatch, useAppSelector } from "@store/reduxStore";
import { getFranchises } from "@core/usecases/franchise/get-franchises/getFranchises";
import FranchiseCard from "@app/components/franchise/franchise-card/FranchiseCard";
import { Input } from "@app/components/ui/input";

const FranchiseList: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const franchises = useAppSelector(state => state.feature.franchise.list);

  const [searchValue, setSearchValue] = useState("");
  const [franchiseSearch, setFranchiseSearch] = useState<Franchise[]>([]);

  useEffect(() => {
    dispatch(getFranchises());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const result = franchises.filter(
      franchise =>
        franchise.company.toLowerCase().includes(searchValue.toLowerCase()) ||
        franchise.title.toLowerCase().includes(searchValue.toLowerCase())
    );

    setFranchiseSearch(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className="h-full text-p-2 text-xl transition-colors overflow-auto">
      <div className="flex flex-col py-10 mx-auto container">
        <div className="flex justify-between items-center mb-10 text-4xl font-medium select-none">
          <div>
            <span className="text-purple-700 dark:text-purple-700">Franchise</span> Scrapper
          </div>
          <div className="text-sm flex gap-2 items-center">
            Search <Input className="p-2" value={searchValue} onChange={e => setSearchValue(e.target.value)} />
          </div>
        </div>

        <div className="mb-10 text-sm font-medium select-none">
          <div>
            <span className="font-bold">Total :</span> {franchises.length}
          </div>
          <div>
            <span className="font-bold">Total unseded:</span>
            {franchises.filter(e => !e.isSended).length}
          </div>
          <div>
            <span className="font-bold">Total send:</span> {franchises.filter(e => e.isSended).length}
          </div>
        </div>

        <div className="flex-1 flex flex-wrap justify-between gap-4 ml-auto mr-auto">
          {franchiseSearch.length > 0
            ? franchiseSearch.map(franchise => <FranchiseCard franchise={franchise} key={franchise._id} />)
            : franchises.map(franchise => <FranchiseCard franchise={franchise} key={franchise._id} />)}
        </div>
      </div>
    </div>
  );
};

export default FranchiseList;
