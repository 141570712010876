import { AppThunk } from "@store/reduxStore";
import { updateNotificationsAction } from "./updateNotificationsAction";
import { Profile } from "@core/gateways/profile/profileGateway";

export const updateNotifications =
  (notifications: Profile["notifications"]): AppThunk =>
  async (dispatch, getState, { profileGateway }) => {
    const profile = getState().profile;
    if (!profile) return;

    const query = await profileGateway.updateNotifications(notifications, profile);
    dispatch(updateNotificationsAction(query));
  };
