import { AppThunk } from "@store/reduxStore";
import { updateProfileAction } from "./updateProfileAction";
import { UpdateProfileType } from "@core/gateways/profile/profileGateway";

export const updateProfile =
  (newProfile: UpdateProfileType): AppThunk =>
  async (dispatch, getState, { profileGateway }) => {
    const profile = getState().profile;
    if (!profile) return;

    const query = await profileGateway.updateProfile(newProfile, profile);
    dispatch(updateProfileAction(query));
  };
