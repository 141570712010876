import { AuthenticationGateway, SigninResponse } from "@core/gateways/authentication/authenticationGateway";
import { Email } from "@core/types/authentication/email";
import { Password } from "@core/types/authentication/password";
import { profileUserMock } from "@root/__mocks__/profile/profileMock";

export class LocalAuthenticationGateway implements AuthenticationGateway {
  protected userEmail = "user@company.com";
  protected goodPassword = "123456";

  async signin(email: Email, password: Password): Promise<SigninResponse | null> {
    return new Promise((resolve, reject) => {
      if (password.toString() === this.goodPassword) {
        resolve({
          email: email.toString(),
          profile: profileUserMock,
        });
      } else {
        reject(new Error("Bad credentials"));
      }
    });
  }

  async askResetPassword(): Promise<void> {
    return new Promise(resolve => resolve());
  }

  async resetPassword(): Promise<SigninResponse | null> {
    return new Promise(resolve => resolve(null));
  }

  async signout(): Promise<boolean> {
    return new Promise(resolve => resolve(true));
  }
}
