import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "@app/components/auth/auth-form/login-form";
import { APP_NAME } from "@configs/env";
import logo from "@app/assets/images/logo.png";
import { useAppSelector } from "@store/reduxStore";
import { indexRoutes } from "@app/routes/urls";

const Login: React.FC = () => {
  const auth = useAppSelector(state => state.authentication);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.email) return;

    navigate(indexRoutes.AUTH);
  }, [navigate, auth]);

  return (
    <>
      <div className="container relative h-full flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="absolute left-4 top-4 md:left-8 md:top-8">
          <img src={logo} className="w-12 h-12 rounded-md" />
        </div>
        <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
          <div className="absolute inset-0 bg-zinc-900" />
          <div className="relative z-20 flex items-center text-lg font-medium">
            <img src={logo} alt="logo" width="24" height="24" className="mr-2 h-6 w-6 rounded-md" />
            {APP_NAME}
          </div>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">&ldquo;Perform your job thanks our powerfull solution ✨.&rdquo;</p>
              <footer className="text-sm">Eric Ziouane</footer>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8 h-full flex justify-center">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">Login</h1>
              <p className="text-sm text-muted-foreground">
                Enter your email and your password to access to your account
              </p>
            </div>
            <LoginForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
