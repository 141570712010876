import { AppThunk } from "@store/reduxStore";
import { getVoicesAction } from "./getVoicesAction";

export const getVoices =
  (): AppThunk =>
  async (dispatch, getState, { textToSpeechGateway }) => {
    const profile = getState().profile;
    if (!profile?.email) return;

    const result = await textToSpeechGateway.getVoices();
    dispatch(getVoicesAction(result));
  };
