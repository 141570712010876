import { createReducer } from "@reduxjs/toolkit";
import { signoutAction } from "@core/usecases/authentication/signout/signoutAction";
import { AppState } from "@store/appState";
import { convertAction as convertImageAction } from "@core/usecases/text-to-image/convert/convertAction";
import { clearAction as clearImageAction } from "@core/usecases/text-to-image/clear/clearAction";
import { convertAction as convertSpeechAction } from "@core/usecases/text-to-speech/convert/convertAction";
import { clearAction as clearSpeechAction } from "@core/usecases/text-to-speech/clear/clearAction";
import { getVoicesAction } from "@core/usecases/text-to-speech/get-voices/getVoicesAction";
import { getFranchisesAction } from "@core/usecases/franchise/get-franchises/getFranchisesAction";
import { updateFranchiseAction } from "@core/usecases/franchise/update-franchise/updateFranchiseAction";
import { getVideosAction } from "@core/usecases/editorz/get-videos/getVideosAction";

const initialState: AppState["feature"] = {
  textToImage: null,
  textToSpeech: {
    result: null,
    voices: [],
  },
  franchise: {
    list: [],
  },
  enigmai: {
    current: {
      messages: [],
    },
  },
  editorz: {
    videosList: [],
  },
};

export const featureReducer = createReducer<AppState["feature"]>(initialState, builder => {
  /**
   * Convert Text to Image
   */
  builder.addCase(convertImageAction, (state, action) => {
    if (!action.payload) return;

    state.textToImage = action.payload;
  });

  /**
   * Clear Text to Image
   */
  builder.addCase(clearImageAction, state => {
    state.textToImage = null;
  });

  /**
   * Convert Text to Speech
   */
  builder.addCase(convertSpeechAction, (state, action) => {
    if (!action.payload) return;

    state.textToSpeech.result = action.payload;
  });

  /**
   * Get voices Text to Speech
   */
  builder.addCase(getVoicesAction, (state, action) => {
    if (!action.payload) return;

    state.textToSpeech.voices = action.payload;
  });

  /**
   * Clear Text to Speech
   */
  builder.addCase(clearSpeechAction, state => {
    state.textToSpeech.result = null;
  });

  /**
   * Get Franchises
   */
  builder.addCase(getFranchisesAction, (state, action) => {
    if (!action.payload) return;

    state.franchise.list = action.payload;
  });

  /**
   * Update Franchise
   */
  builder.addCase(updateFranchiseAction, (state, { payload }) => {
    if (!payload) return;

    const franchiseIndex = state.franchise.list.findIndex(franchise => franchise.email === payload.email);
    if (franchiseIndex === -1) return;

    state.franchise.list[franchiseIndex] = payload;
  });

  /**
   * Get Videos
   */
  builder.addCase(getVideosAction, (state, action) => {
    if (!action.payload) return;

    state.editorz.videosList = action.payload;
  });

  /**
   * Sign out
   */
  builder.addCase(signoutAction, state => {
    state.textToImage = null;
    state.textToSpeech = {
      result: null,
      voices: [],
    };
    state.franchise = {
      list: [],
    };
    state.enigmai = {
      current: {
        messages: [],
      },
    };
    state.editorz = {
      videosList: [],
    };
  });
});
