import { indexRoutes } from "../urls";

export const featureRoutes = {
  BASENAME: `${indexRoutes.AUTH}/feature`,
  DASHBOARD: `${indexRoutes.AUTH}/feature`,
  TEXT_TO_IMAGE: `${indexRoutes.AUTH}/feature/text-to-image`,
  TEXT_TO_SPEECH: `${indexRoutes.AUTH}/feature/text-to-speech`,
  FRANCHISE_SCRAPPER: `${indexRoutes.AUTH}/feature/franchise-scrapper`,
  ENIGMAI: `${indexRoutes.AUTH}/feature/enigmai`,
  EDITORZ: `${indexRoutes.AUTH}/feature/editorz`,
};
