import { createBrowserRouter } from "react-router-dom";
import Login from "@app/pages/public/login/Login";
import NotFound from "@app/pages/public/not-found/NotFound";
import RootLayout from "@app/layouts/RootLayout";
import { indexRoutes } from "./urls";
import { userRouter } from "./user/user-routes";
import AskResetPassword from "@app/pages/public/ask-reset-password/AskResetPassword";
import ResetPassword from "@app/pages/public/reset-password/ResetPassword";
import FeatureLayout from "@app/layouts/FeatureLayout";
import { featureRouter } from "./feature/feature-routes";
import AuthLayout from "@app/layouts/AuthLayout";

export const router = createBrowserRouter([
  {
    path: indexRoutes.BASENAME,
    element: <RootLayout />,
    children: [
      {
        path: indexRoutes.BASENAME,
        element: <Login />,
      },
      {
        path: indexRoutes.LOGIN,
        element: <Login />,
      },
      {
        path: indexRoutes.ASK_RESET_PASSWORD,
        element: <AskResetPassword />,
      },
      {
        path: indexRoutes.RESET_PASSWORD,
        element: <ResetPassword />,
      },
      {
        path: indexRoutes.ALL, // 404
        element: <NotFound />,
      },
      {
        path: indexRoutes.AUTH,
        element: <AuthLayout />,
        children: [
          {
            element: <FeatureLayout />,
            children: featureRouter,
          },
          { children: userRouter },
        ],
      },
    ],
  },
]);

export type AppRoutes = typeof router;
