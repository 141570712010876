import { Franchise } from "@core/gateways/franchise/FranchiseGateway";
import { AppThunk } from "@store/reduxStore";
import { updateFranchiseAction } from "./updateFranchiseAction";

export const updateFranchise =
  (franchise: Franchise, value: "unsended" | "sended"): AppThunk =>
  async (dispatch, getState, { franchiseGateway }) => {
    const profile = getState().profile;
    if (!profile?.email) return;

    const result = await franchiseGateway.updateFranchise(franchise, value);
    dispatch(updateFranchiseAction(result));
  };
