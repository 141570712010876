import { AppGateway, ThemeType } from "@core/gateways/app/appGateway";
import { ToastGateway } from "@core/gateways/toast/toastGateway";
import { Supabase } from "@services/supabase";

export class SupabaseAppGateway implements AppGateway {
  protected cloud: Supabase;
  protected toast: ToastGateway;

  constructor(cloud: Supabase, toast: ToastGateway) {
    this.cloud = cloud;
    this.toast = toast;
  }

  async updateTheme(theme: ThemeType): Promise<ThemeType> {
    return new Promise(resolve => {
      this.toast.show("Theme updated", `Theme has been updated to ${theme}`);
      resolve(theme);
    });
  }
}
