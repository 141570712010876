import { indexRoutes } from "../urls";

export const userRoutes = {
  BASENAME: `${indexRoutes.AUTH}/user`,
  DASHBOARD: `${indexRoutes.AUTH}/user`,
  SETTINGS: `${indexRoutes.AUTH}/user/settings`,
  PROFILE: `${indexRoutes.AUTH}/user/settings`,
  ACCOUNT: `${indexRoutes.AUTH}/user/settings/account`,
  APPEARANCE: `${indexRoutes.AUTH}/user/settings/appearance`,
  NOTIFICATIONS: `${indexRoutes.AUTH}/user/settings/notifications`,
};
