import { createReducer } from "@reduxjs/toolkit";
import { signoutAction } from "@core/usecases/authentication/signout/signoutAction";
import { AppState } from "@store/appState";
import { updateThemeAction } from "@core/usecases/app/update-theme/updateThemeAction";

const initialState: AppState["app"] = {
  theme: "system",
};

export const appReducer = createReducer<AppState["app"]>(initialState, builder => {
  /**
   * updateThemeAction
   */
  builder.addCase(updateThemeAction, (state, action) => {
    if (!action.payload) return;

    state.theme = action.payload;
  });

  /**
   * Sign out
   */
  builder.addCase(signoutAction, state => {
    state.theme = "system";
  });
});
