import { ImageResult, TextToImageGateway } from "@core/gateways/text-to-image/textToImageGateway";
import { ToastGateway } from "@core/gateways/toast/toastGateway";
import { Supabase } from "@services/supabase";

export class SupabaseTextToImageGateway implements TextToImageGateway {
  protected cloud: Supabase;
  protected toast: ToastGateway;

  constructor(cloud: Supabase, toast: ToastGateway) {
    this.cloud = cloud;
    this.toast = toast;
  }

  async convert(text: string): Promise<ImageResult | null> {
    try {
      const { data } = await this.cloud.functions.invoke<ImageResult | null>("text-to-image", {
        body: JSON.stringify({ prompt: text }),
      });

      return data?.url
        ? {
            url: data.url,
            revisedPrompt: data.revisedPrompt ?? text,
          }
        : null;
    } catch (error) {
      return null;
    }
  }
}
