import { AppThunk } from "@store/reduxStore";
import { updateAccountAction } from "./updateAccountAction";
import { Account } from "@core/gateways/profile/profileGateway";

export const updateAccount =
  (newAccount: Account): AppThunk =>
  async (dispatch, getState, { profileGateway }) => {
    const profile = getState().profile;
    if (!profile) return;

    const query = await profileGateway.updateAccount(newAccount, profile);
    dispatch(updateAccountAction(query));
  };
