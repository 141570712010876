import React from "react";
import { ResizablePanel } from "@app/components/ui/resizable";
import { defaultLayout } from "@app/layouts/AuthLayout";

const Dashboard: React.FC = () => {
  return (
    <ResizablePanel defaultSize={defaultLayout[1]} minSize={30}>
      <div>he</div>
    </ResizablePanel>
  );
};

export default Dashboard;
