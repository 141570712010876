/* eslint-disable @typescript-eslint/no-misused-promises */
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@app/components/ui/button";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel } from "@app/components/ui/form";
import { Switch } from "@app/components/ui/switch";
import { useToast } from "@app/components/ui/use-toast";
import { updateNotifications } from "@core/usecases/profile/update-notifications/updateNotifications";
import { useAppDispatch, useAppSelector } from "@store/reduxStore";
import { useState } from "react";
import { Icons } from "@app/components/ui/icon";

const notificationsFormSchema = z.object({
  push: z.boolean(),
  communication: z.boolean(),
  security: z.boolean(),
});

type NotificationsFormValues = z.infer<typeof notificationsFormSchema>;

export function NotificationsForm() {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(state => state.profile.notifications);
  const { toast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const defaultValues: Partial<NotificationsFormValues> = {
    push: notifications.push,
    communication: notifications.communication,
    security: notifications.security,
  };

  const form = useForm<NotificationsFormValues>({
    resolver: zodResolver(notificationsFormSchema),
    defaultValues,
  });

  function onSubmit(data: NotificationsFormValues) {
    toast({
      title: "You submitted the following values:",
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ),
    });
  }

  const handleTest = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      form.handleSubmit(onSubmit);
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(
        updateNotifications({
          push: form.getValues("push"),
          communication: form.getValues("communication"),
          security: form.getValues("security"),
        })
      );

      toast({
        title: "Notifications settings updated",
        description: "Your notifications settings have been updated.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={handleTest} className="space-y-8">
        <FormField
          control={form.control}
          name="push"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
              <div className="space-y-0.5">
                <FormLabel className="text-base">Notify me about...</FormLabel>
                <FormDescription>Receive push notification about your account activity.</FormDescription>
              </div>
              <FormControl>
                <Switch checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
            </FormItem>
          )}
        />
        <div>
          <h3 className="mb-4 text-lg font-medium">Email Notifications</h3>
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="communication"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">Communication emails</FormLabel>
                    <FormDescription>Receive emails about your account activity.</FormDescription>
                  </div>
                  <FormControl>
                    <Switch checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="security"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">Security emails</FormLabel>
                    <FormDescription>Receive emails about your account activity and security.</FormDescription>
                  </div>
                  <FormControl>
                    <Switch checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>

        <Button type="submit" disabled={isLoading}>
          {isLoading && <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />}
          Update notifications
        </Button>
      </form>
    </Form>
  );
}
