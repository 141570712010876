import React, { useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { cn } from "@lib/utils";
import { useAppDispatch, useAppSelector } from "@store/reduxStore";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@app/components/ui/resizable";
import { TooltipProvider } from "@app/components/ui/tooltip";
import { Separator } from "@app/components/ui/separator";
import favicon from "@app/assets/images/favicon.png";
import Nav, { NavProps } from "@app/components/user/dashboard/nav";
import { Bot, GalleryHorizontalIcon, LogOut, PaintBucket, Scroll, Settings, VoicemailIcon } from "lucide-react";
import { userRoutes } from "@app/routes/user/urls";
import { signout } from "@core/usecases/authentication/signout/signout";
import { indexRoutes } from "@app/routes/urls";
import { featureRoutes } from "@app/routes/feature/urls";

export const defaultLayout = [265, 440, 655];

const AuthLayout: React.FC = () => {
  const navCollapsedSize = 4;
  const { pathname } = useLocation();

  const match = pathname.match(/\/user\/settings/);
  const settingsPath = match ? match[0] : null;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(state => state.profile);

  const [isCollapsed, setIsCollapsed] = useState(false);

  if (!profile.email) {
    return <Navigate to={indexRoutes.LOGIN} replace />;
  }

  const linksList: NavProps[] = [
    {
      title: "EnigmAI",
      label: "",
      icon: Bot,
      variant: pathname === featureRoutes.ENIGMAI ? "secondary" : "ghost",
      action: () => navigate(featureRoutes.ENIGMAI),
    },
    {
      title: "Settings",
      label: "",
      icon: Settings,
      variant: pathname === userRoutes.SETTINGS || settingsPath ? "secondary" : "ghost",
      action: () => navigate(userRoutes.SETTINGS),
    },
    {
      title: "Log out",
      label: "",
      icon: LogOut,
      variant: "ghost",
      action: () => dispatch(signout()),
    },
  ];

  (profile.role === "SALE" || profile.role === "ADMIN") &&
    linksList.unshift({
      title: "Franchise Scrapper",
      label: "",
      icon: Scroll,
      variant: pathname === featureRoutes.FRANCHISE_SCRAPPER ? "secondary" : "ghost",
      action: () => navigate(featureRoutes.FRANCHISE_SCRAPPER),
    });

  (profile.role === "CREATOR" || profile.role === "ADMIN") &&
    linksList.unshift({
      title: "Editorz",
      label: "",
      icon: GalleryHorizontalIcon,
      variant: pathname === featureRoutes.EDITORZ ? "secondary" : "ghost",
      action: () => navigate(featureRoutes.EDITORZ),
    });

  (profile.role === "CREATOR" || profile.role === "ADMIN") &&
    linksList.unshift(
      {
        title: "Open Voice",
        label: "",
        icon: VoicemailIcon,
        variant: pathname === featureRoutes.TEXT_TO_SPEECH ? "secondary" : "ghost",
        action: () => navigate(featureRoutes.TEXT_TO_SPEECH),
      },
      {
        title: "Artist Ik",
        label: "",
        icon: PaintBucket,
        variant: pathname === featureRoutes.TEXT_TO_IMAGE ? "secondary" : "ghost",
        action: () => navigate(featureRoutes.TEXT_TO_IMAGE),
      }
    );

  return (
    <TooltipProvider delayDuration={0}>
      <ResizablePanelGroup
        direction="horizontal"
        onLayout={(sizes: number[]) => {
          document.cookie = `react-resizable-panels:layout=${JSON.stringify(sizes)}`;
        }}
        className="items-stretch"
      >
        <ResizablePanel
          defaultSize={defaultLayout[0]}
          collapsedSize={navCollapsedSize}
          collapsible={true}
          maxSize={30}
          minSize={15}
          onCollapse={() => setIsCollapsed(true)}
          onExpand={() => setIsCollapsed(false)}
          className={cn(isCollapsed && "min-w-[50px] max-w-[55px] transition-all duration-300 ease-in-out")}
        >
          <div className={cn("flex h-[52px] items-center", isCollapsed ? "h-[52px] justify-center" : "px-2")}>
            <img
              onClick={() => navigate(userRoutes.DASHBOARD)}
              src={favicon}
              width={32}
              height={32}
              className="cursor-pointer rounded-md w-8 h-8"
            />
          </div>
          <Separator />
          <Nav isCollapsed={isCollapsed} links={linksList} />
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel
          defaultSize={defaultLayout[1]}
          collapsedSize={navCollapsedSize}
          collapsible={true}
          onCollapse={() => setIsCollapsed(true)}
          onExpand={() => setIsCollapsed(false)}
          className={cn(isCollapsed && "transition-all duration-300 ease-in-out")}
        >
          <Outlet />
        </ResizablePanel>
      </ResizablePanelGroup>
    </TooltipProvider>
  );
};

export default AuthLayout;
