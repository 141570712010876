import React, { useState } from "react";
import { convert } from "@core/usecases/text-to-image/convert/convert";
import { clear } from "@core/usecases/text-to-image/clear/clear";
import { Button } from "@app/components/ui/button";
import { ReloadIcon } from "@radix-ui/react-icons";
import { DownloadIcon } from "lucide-react";
import { Checkbox } from "@app/components/ui/checkbox";
import { Textarea } from "@app/components/ui/textarea";
import { useToast } from "@app/components/ui/use-toast";
import { useAppDispatch, useAppSelector } from "@store/reduxStore";

const TextToImage: React.FC = (): JSX.Element => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const result = useAppSelector(state => state.feature.textToImage);

  const [loading, setLoading] = useState(false);
  const [showRevised, setShowRevised] = useState(false);
  const [prompt, setPrompt] = useState("");

  const handleConvert = async (): Promise<void> => {
    setLoading(true);
    try {
      await dispatch(convert(prompt));
    } catch (error) {
      toast({
        title: "Error",
        description: JSON.stringify(error),
      });
    } finally {
      setLoading(false);
    }
  };

  const downloadImage = () => {
    if (!result?.url) {
      toast({
        title: "Error",
        description: "No image to download",
      });
      return;
    }

    window.open(result.url, "_blank");
  };

  const resetImage = () => {
    dispatch(clear());
  };

  return (
    <div className="w-full h-full relative text-p-2 text-xl transition-colors">
      <div className="w-full h-full flex flex-col items-center justify-center mb-10 z-10">
        <div className="w-[80%] z-50">
          <div className="flex justify-center mb-10 text-4xl font-medium text-slate-800 dark:text-slate-200 select-none">
            <span className="text-green-700 dark:text-green-700">Artist</span> Ik
          </div>
          <div className="flex flex-col">
            <div className="mb-2 text-lg">Write your prompt</div>
            <Textarea
              placeholder="Beautiful butterfly that invade fish and chips"
              value={prompt}
              onChange={e => setPrompt(e.target.value)}
              required
            />
            <div className="mb-4" />
            <div className="flex items-center gap-2 text-sm">
              <Checkbox checked={showRevised} onCheckedChange={e => setShowRevised(Boolean(e))} />
              Show revised prompt
            </div>
            <div className="mb-10" />
            <Button disabled={loading} onClick={() => handleConvert()}>
              {loading ? "Converting..." : "Get result"}
            </Button>
          </div>
        </div>
      </div>
      {result ? (
        <div className="sticky bottom-5 left-0 right-0 z-50 border-t border-slate-400 bg-white dark:bg-neutral-950 p-4 mx-5 flex flex-col-reverse sm:flex-row gap-5">
          <div className="flex-1">
            <div className="pb-5 flex justify-center">
              <img className="w-52 h-52" src={result.url} />
            </div>
            {showRevised ? (
              <div className="text-sm">
                <span className="underline">Revised prompt:</span> {result.revisedPrompt}
              </div>
            ) : null}
          </div>
          <div className="flex-1 flex flex-col gap-10 justify-center">
            <Button onClick={() => downloadImage()}>
              <DownloadIcon />
              Download
            </Button>
            <Button onClick={() => resetImage()}>
              <ReloadIcon />
              Reset
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TextToImage;
