import React, { useState } from "react";
import { Mail, RefreshCcw } from "lucide-react";
import { Button } from "@app/components/ui/button";
import { Franchise } from "@core/gateways/franchise/FranchiseGateway";
import { Badge } from "@app/components/ui/badge";
import { useAppDispatch } from "@store/reduxStore";
import { useToast } from "@app/components/ui/use-toast";
import { updateFranchise } from "@core/usecases/franchise/update-franchise/updateFranchise";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@app/components/ui/card";

interface Props {
  franchise: Franchise;
}

const FranchiseCard: React.FC<Props> = ({ franchise }) => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const handleUpdateFranchise = async (franchise: Franchise, status: "unsended" | "sended") => {
    setLoading(true);
    try {
      await dispatch(updateFranchise(franchise, status));
    } catch (error) {
      toast({
        title: "Error",
        description: JSON.stringify(error),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOnCopyMail = (franchise: Franchise) => {
    const recipient = franchise.email;
    const subject = `Parteneriat entre ${franchise.company} et Dezzzign`;

    const nameRegex = /M(?:me|.)\s+([A-Za-zÀ-ÖØ-öø-ÿ]+(?:\s[A-Za-zÀ-ÖØ-öø-ÿ]+)*)/;
    const match = franchise.title.match(nameRegex) ?? "";

    const body = `Bonjour ${match[1]},\n\nJe vous contacte pour vous présenter une opportunité qui, je pense, pourrait être très avantageuse pour ${franchise.company}.\nImaginez une solution de création graphique illimitée, disponible sous votre propre marque.\nGrâce à cette offre, vos franchisés auraient la possibilité de commander des designs personnalisés via une plateforme à la fois simple et intuitive, conçue spécifiquement pour répondre à leurs exigences.\nNos designers talentueux prendraient en charge ces projets, garantissant des résultats à la fois innovants et professionnels.\n\nNous sommes en mesure de vous offrir des tarifs compétitifs.\nPour vous donner une idée concrète de ce que nous offrons, nous serions ravis d'organiser une démonstration gratuite de nos services.\nCette présentation vous permettrait de découvrir la facilité d'utilisation de notre plateforme et la qualité exceptionnelle de nos réalisations.\n\nPensez-vous que cette proposition pourrait intéresser ${franchise.company} ?\n\nCordialement,\nÉric`;

    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);

    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipient}&su=${encodedSubject}&body=${encodedBody}`;
    window.open(gmailLink, "_blank");
  };

  return (
    <Card className="min-w-[350px] flex-1 text-base">
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          <span className="text-xl">{franchise.title}</span>
          {franchise.isSended ? (
            <Badge
              className={`gap-2 ${loading ? "cursor-wait" : "cursor-pointer"} bg-green-600 text-white`}
              onClick={() => handleUpdateFranchise(franchise, "unsended")}
            >
              {loading ? <RefreshCcw className="animate-spin" size={16} /> : null} Sended
            </Badge>
          ) : (
            <Badge
              variant="destructive"
              className={`gap-2 ${loading ? "cursor-wait" : "cursor-pointer"} text-white`}
              onClick={() => handleUpdateFranchise(franchise, "sended")}
            >
              {loading ? <RefreshCcw className="animate-spin" size={16} /> : null} Unsended
            </Badge>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p>
          <a href={`mailto:${franchise.email}`}>{franchise.email}</a>
        </p>
        <p>
          <a href={`tel:${franchise.phone}`}>{franchise.phone}</a>
        </p>
        <p>
          <a href={`https://${franchise.website}`}>{franchise.website}</a>
        </p>
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button className="gap-2" onClick={() => handleOnCopyMail(franchise)}>
          <Mail />
          New mail
        </Button>
      </CardFooter>
    </Card>
  );
};

export default FranchiseCard;
