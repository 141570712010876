/** @jsxImportSource ai-jsx/react */
// const KEY =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJmaXhpZS5haS9wcm9kIiwiYXVkIjoiaHR0cHM6Ly9maXhpZS5haSIsInN1YiI6IjI3NzgifQ.nAuGDD-0d_ckT0YKl_aGquLa7SeGVrbvTg8wJOikdPA";

// const Chat: React.FC = () => {
//   const [messages, { push: pushMessage, updateAt: updateMessage }] = useList<string>([]);
//   const [callInProgress, setCallInProgress] = useState(false);

//   return (
//     <main className="h-full flex flex-col">
//       <div className="relative">
//         <div className="p-4 flex-1 flex justify-center gap-2 items-center text-center">
//           EnigmAI{" "}
//           <Badge variant="outline" className="border-purple-700 font-light">
//             Beta
//           </Badge>
//         </div>
//       </div>
//       <Separator className="mt-auto" />
//       <div className="flex-1 whitespace-pre-wrap p-4 text-sm overflow-scroll">
//         <section className="w-full h-full flex flex-col relative">
//           <div className="flex-1 w-full h-full flex-grow flex flex-col justify-between">
//             <div className="overflow-y-auto flex flex-col gap-5 mb-5">
//               <ul>
//                 {messages.map((response, index) => [
//                   <li key={index} className="mt-4">
//                     {response}
//                   </li>,
//                 ])}
//               </ul>
//             </div>
//           </div>
//         </section>
//       </div>
//       <Separator className="mt-auto" />
//       <div className="p-4">
//         <div className="flex gap-4">
//           <Textarea className="p-4" placeholder={`Response to this email: `} />
//           <div className="flex items-center">
//             <Button onClick={e => e.preventDefault()} size="sm" className="ml-auto" title="Send">
//               <Send className="h-4 w-4" />
//             </Button>
//           </div>
//         </div>
//       </div>
//     </main>
//   );
// };

// export default Chat;
import { Button } from "@app/components/ui/button";
import { useAIStream } from "ai-jsx/react";
import { SendIcon } from "lucide-react";
import { useState } from "react";

export default function Chat() {
  const DEFAULT_PROMPT = "A red panda who likes to eat grapes";
  const { current, fetchAI } = useAIStream({});
  const [topic, setTopic] = useState(DEFAULT_PROMPT);

  return (
    <div className="h-screen flex flex-col gap-4 p-4">
      <div className="rounded-sm bg-white flex-1 p-3">{current ?? ""}</div>
      <div className="flex gap-4 items-center pl-6 py-2 pr-2 border rounded-full">
        <input
          value={topic}
          multiple
          onChange={e => setTopic(e.currentTarget.value)}
          className="flex-1 focus:outline-none rounded-full"
        />
        <Button
          disabled={topic.trim() === ""}
          className="rounded-full"
          onClick={() => {
            fetchAI("https://", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ topic }),
            });
          }}
        >
          <SendIcon size={16} />
        </Button>
      </div>
    </div>
  );
}
