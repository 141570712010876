import { AppThunk } from "@store/reduxStore";
import { getFranchisesAction } from "./getFranchisesAction";

export const getFranchises =
  (): AppThunk =>
  async (dispatch, getState, { franchiseGateway }) => {
    const profile = getState().profile;
    if (!profile?.email) return;

    const result = await franchiseGateway.getFranchises();

    dispatch(getFranchisesAction(result));
  };
