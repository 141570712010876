import { SB_KEY, SB_URL } from "@configs/env";
import { createClient } from "@supabase/supabase-js";
import { Database } from "@root/supabase/functions/_share/types/publicSchema";

const supabaseClient = createClient<Database>(SB_URL, SB_KEY, {
  auth: {
    storage: localStorage,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
});

export const supabase = supabaseClient;

export type Supabase = typeof supabase;
