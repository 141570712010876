import { AppThunk } from "@store/reduxStore";
import { convertAction } from "./convertAction";

export const convert =
  (text: string): AppThunk =>
  async (dispatch, getState, { textToImageGateway }) => {
    const profile = getState().profile;
    if (!profile?.email) return;

    const result = await textToImageGateway.convert(text);
    dispatch(convertAction(result));
  };
