import { AppThunk } from "@store/reduxStore";
import { Voice } from "@core/gateways/text-to-speech/textToSpeechGateway";
import { convertAction } from "./convertAction";

export const convert =
  (text: string, voice: Voice): AppThunk =>
  async (dispatch, getState, { textToSpeechGateway }) => {
    const profile = getState().profile;
    if (!profile?.email) return;

    const result = await textToSpeechGateway.convert(text, voice);
    dispatch(convertAction(result));
  };
