import { Dependencies } from "@store/reduxStore";
import { LocalAuthenticationGateway } from "@adapters/gateways/authentication/LocalAuthenticationGateway";
import { LocalAppGateway } from "@adapters/gateways/app/LocalAppGateway";
import { SupabaseAuthenticationGateway } from "./authentication/SupabaseAuthenticationGateway";
import { supabase } from "@services/supabase";
import { MODE } from "@configs/env";
import { SupabaseAppGateway } from "./app/SupabaseAppGateway";
import { ShadcnToastGateway } from "./toast/ShadcnToastGateway";
import { SupabaseProfileGateway } from "./profile/SupabaseProfileGateway";
import { LocalProfileGateway } from "./profile/LocalProfileGateway";
import { SupabaseTextToImageGateway } from "./text-to-image/SupabaseTextToImageGateway";
import { SupabaseTextToSpeechGateway } from "./text-to-speech/SupabaseTextToSpeechGateway";
import { RealMWebFranchiseGateway } from "./franchise/RealMWebFranchiseGateway";
import { SupabaseEditorzGateway } from "./editorz/SupabaseEditorzGateway";

const toast = new ShadcnToastGateway();

const localGateways: Dependencies = {
  appGateway: new LocalAppGateway(),
  authenticationGateway: new LocalAuthenticationGateway(),
  profileGateway: new LocalProfileGateway(),
  toastGateway: new ShadcnToastGateway(),
  textToImageGateway: new SupabaseTextToImageGateway(supabase, toast),
  textToSpeechGateway: new SupabaseTextToSpeechGateway(supabase, toast),
  franchiseGateway: new RealMWebFranchiseGateway(),
  editorzGateway: new SupabaseEditorzGateway(supabase, toast),
};

const supabaseGateways: Dependencies = {
  appGateway: new SupabaseAppGateway(supabase, toast),
  authenticationGateway: new SupabaseAuthenticationGateway(supabase, toast),
  profileGateway: new SupabaseProfileGateway(supabase, toast),
  toastGateway: new ShadcnToastGateway(),
  textToImageGateway: new SupabaseTextToImageGateway(supabase, toast),
  textToSpeechGateway: new SupabaseTextToSpeechGateway(supabase, toast),
  franchiseGateway: new RealMWebFranchiseGateway(),
  editorzGateway: new SupabaseEditorzGateway(supabase, toast),
};

const getGateways = (mode: typeof MODE): Dependencies => {
  switch (mode) {
    case "local":
      return localGateways;
    case "production":
      return supabaseGateways;
    default:
      return localGateways;
  }
};

export const gatewaySelectors: Dependencies = getGateways("production");
