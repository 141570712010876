import { EditorzGateway, Video } from "@core/gateways/editorz/EditorzGateway";
import { ToastGateway } from "@core/gateways/toast/toastGateway";
import { Supabase } from "@services/supabase";

export class SupabaseEditorzGateway implements EditorzGateway {
  protected cloud: Supabase;
  protected toast: ToastGateway;

  constructor(cloud: Supabase, toast: ToastGateway) {
    this.cloud = cloud;
    this.toast = toast;
  }

  async getVideos(text: string): Promise<Video[]> {
    try {
      const { data } = await this.cloud.from("videos").select("*").ilike("title", `%${text}%`);

      console.log({ data });

      return [];
    } catch (error) {
      this.toast.show("⛔️ Error", "Error getting videos");
      console.log(error);
      return [];
    }
  }
}
